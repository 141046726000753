// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-algorithms-js": () => import("./../../src/pages/tags/Algorithms.js" /* webpackChunkName: "component---src-pages-tags-algorithms-js" */),
  "component---src-pages-tags-c-js": () => import("./../../src/pages/tags/C.js" /* webpackChunkName: "component---src-pages-tags-c-js" */),
  "component---src-pages-tags-course-js": () => import("./../../src/pages/tags/Course.js" /* webpackChunkName: "component---src-pages-tags-course-js" */),
  "component---src-pages-tags-data-structure-js": () => import("./../../src/pages/tags/DataStructure.js" /* webpackChunkName: "component---src-pages-tags-data-structure-js" */),
  "component---src-pages-tags-dp-js": () => import("./../../src/pages/tags/DP.js" /* webpackChunkName: "component---src-pages-tags-dp-js" */),
  "component---src-pages-tags-editor-js": () => import("./../../src/pages/tags/Editor.js" /* webpackChunkName: "component---src-pages-tags-editor-js" */),
  "component---src-pages-tags-gatsby-js": () => import("./../../src/pages/tags/Gatsby.js" /* webpackChunkName: "component---src-pages-tags-gatsby-js" */),
  "component---src-pages-tags-graphql-js": () => import("./../../src/pages/tags/Graphql.js" /* webpackChunkName: "component---src-pages-tags-graphql-js" */),
  "component---src-pages-tags-index-js": () => import("./../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-tags-js-js": () => import("./../../src/pages/tags/JS.js" /* webpackChunkName: "component---src-pages-tags-js-js" */),
  "component---src-pages-tags-tree-js": () => import("./../../src/pages/tags/Tree.js" /* webpackChunkName: "component---src-pages-tags-tree-js" */),
  "component---src-pages-tags-type-script-js": () => import("./../../src/pages/tags/TypeScript.js" /* webpackChunkName: "component---src-pages-tags-type-script-js" */),
  "component---src-pages-tags-work-js": () => import("./../../src/pages/tags/Work.js" /* webpackChunkName: "component---src-pages-tags-work-js" */),
  "component---src-pages-tags-學校課程-js": () => import("./../../src/pages/tags/學校課程.js" /* webpackChunkName: "component---src-pages-tags-學校課程-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

